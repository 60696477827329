<template>
  <div class="nav-search">
    <div class="search-input">
      <i class="material-icons search-icon">search</i>
      <input
        type="text"
        placeholder="What are you looking for..."
        id="search-input"
      />
      <div class="search-dropdown" id="hits-container"></div>
      <i class="close-search material-icons">close</i>
    </div>
  </div>
</template>

<script>
$(function () {
  $("#search-input").on("focus", function () {
    $(".nav-search").addClass("active");
  });

  $("#search-input").on("keyup", function () {
    $("#hits-container").scrollTop(0);
  });

  $(".close-search").on("click", function (evt) {
    evt.preventDefault();
    $("#search-input").val("");
    $(".nav-search").removeClass("active");
  });

  $("#search-input").on("blur", function (evt) {
    if (!evt.isDefaultPrevented) {
      console.log("blur");
      $(".nav-search").removeClass("active");
    }
  });

  let search = instantsearch({
    appId: "TCEAIJWSQQ",
    apiKey: "0a339ae0728b5cb0c3e80fd18141c8cd",
    indexName: "bt_support_articles",
    searchParameters: { replaceSynonymsInHighlight: false },
    searchFunction: function (helper) {
      var searchResults = $(".search-results");
      if (helper.state.query === "") {
        searchResults.hide();
        return;
      }
      helper.search();
      searchResults.show();
    },
  });

  // add a searchBox widget
  search.addWidget(
    instantsearch.widgets.searchBox({
      container: "#search-input",
      placeholder: "Search for libraries in France...",
    })
  );

  // add a hits widget
  search.addWidget(
    instantsearch.widgets.hits({
      container: "#hits-container",
      hitsPerPage: 10,
      debug: true,
      templates: {
        item: '<a href="{{href}}" target="_blank"><div class="hit"><div class="hit-content"><h2 class="hit-title">{{{_highlightResult.title.value}}}</h2><br><small>{{lvl0}} {{#lvl1}}> {{{_highlightResult.lvl1.value}}} {{/lvl1}}{{#lvl2}}> {{{_highlightResult.lvl1.value}}} {{/lvl2}}{{#lvl3}}> {{{_highlightResult.lvl3.value}}} {{/lvl3}} {{#lvl4}}> {{{_highlightResult.lvl4.value}}}{{/lvl4}}</small><p class="hit-description">{{{_snippetResult.content.value}}}</p></div></div></a>',
        empty:
          '<div id="no-results-message"> <p>We didn`t find any results for the search <em>"{{query}}"</em>.</p></div>',
      },
    })
  );

  // start
  search.start();
});

function toTitleCase(str) {
  return str.replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}
</script>

<style lang="scss">
html {
  height: 100%;
}

body {
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  * {
    box-sizing: border-box;
  }
}

.nav-search {
  margin-left: 50px;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  position: relative;
  width: 300px;
  max-width: 450px;
  height: 47px;
  margin-top: 50px;
}

.nav-search.active {
  box-shadow: 0 4px 4px rgba(79, 79, 79, 0.21);

  .search-dropdown {
    display: block;
  }
}

.nav-search.active .search-input {
  -webkit-animation: expand-search-box-animation 0.5s forwards;
  animation: expand-search-box-animation 0.5s forwards;
}

.nav-search.active .search-input input {
  border-width: 2px;
}

.nav-search.active .search-input .close-search {
  display: inline-block;
}

.nav-search.active .search-input .search-dropdown {
  display: block;
}

.nav-search .search-input {
  transition: left 0.2s ease-in-out;
  transition: width 0s ease-in-out;
}

.nav-search .search-input .search-icon {
  position: absolute;
  left: 15px;
  top: 13px;
  z-index: 999;
  color: #44a948;
}

.nav-search .search-input input {
  font: 16px/1.875 "Avenir Next W01", "Avenir Next", "Helvetica Neue", Helvetica,
    sans-serif;
  height: 50px;
  border: 1px solid #44a948;
  border-radius: 4px;
  min-width: 300px;
  width: 100%;
  padding-left: 50px;
}

.nav-search .search-input input:focus {
  outline: none;
}

.nav-search .search-input i.close-search {
  color: #44a948;
  display: none;
  position: absolute;
  right: 15px;
  top: 13px;
  cursor: pointer;
}

.search-dropdown {
  box-sizing: border-box;
  color: #b3b3b3;
  font: 14px/1.875 "Avenir Next W01", "Avenir Next", "Helvetica Neue", Helvetica,
    sans-serif;
  opacity: 1;
  padding: 20px;
  width: 100%;
  -webkit-animation: expand-search-dropdown-animation 0.5s forwards;
  animation: expand-search-dropdown-animation 0.5s forwards;
  overflow-y: scroll;
  max-height: 400px;
  border-radius: 0 0 4px 4px;
  background-color: #fcfcfc;
  border: 1px solid #e0e0e0;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.09);
  display: none;
}

.search-dropdown .small {
  -webkit-flex-basis: 35%;
  -ms-flex-preferred-size: 35%;
  flex-basis: 35%;
}

.search-dropdown .search-section .hits-blank {
  color: #666;
  text-align: center;
  padding-top: 20px;
}

.search-dropdown a {
  text-decoration: none;
  color: inherit;
}

.hit {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;

  .hit-title {
    color: #44a948;
    font-family: "bt_mono", monospace;
    border-bottom: 1px solid #44a948;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
    display: inline-block;
    font-size: 14px;
  }
  .hit-description {
    text-decoration: none;
    color: black;
    font-size: 14px;
    display: block;
    margin-top: 3px;
  }
  .hit-anchor {
    font-size: 13px;
    color: #666;
  }
  .algolia-docsearch-suggestion--highlight {
    background-color: #ffe9a4;
  }

  &:last-child {
    /*border: 0;*/
  }
}

.ais-hits--item:last-child {
  .hit {
    border: 0;
  }
}
</style>
